export function StagingConfig(host: string, production: boolean = true) {
    return {
        production,
        host,
        stage: 'staging',
        appId: '20374bef-2df6-4343-b448-a9f3517b92d5',
        bugsnag: {
            api_key: 'de4f46b3edd5e63b600ac1eb6d2c7751',
        },
        principalDisplayName: 'Octiga Cloud Security - Staging',
        stripe: {
            pk: 'pk_test_51HH61vETpOxjeWDyLDTuOoiHrrG7n8zxUepGqJjyDBk2txhFdeSmwxc3TbgRomLQS2dIEOSuItCknzbtaSkWHQP200s1hfm9OZ',
            prices: {
                premium: 'price_1HP6yKETpOxjeWDytx40TnVz',
            },
        },
        oauth: {
            client: {
                client_id: '20374bef-2df6-4343-b448-a9f3517b92d5',
                authenticate_redirect_uri: `${host}/api/microsoft/oauth/authenticate`,
                authorise_redirect_uri: `${host}/api/microsoft/oauth/authorise`,
                return_url: `${host}/dashboard`,
            },
            msp: {
                client_id: '05d58e5b-f79f-4648-bba0-261d68d1a6ac',
                authenticate_redirect_uri: `${host}/api/microsoft/oauth/authenticate/msp`,
                return_url: `${host}/msp/dashboard`,
            },
        },
    };
}

export const environment = StagingConfig('https://staging.octiga.io');
